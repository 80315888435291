import axios from "axios";
const api = axios.create();

export default {
  cloud_name: "yolllo-com",
  preset: "bcan8mr3",
  async getCloudinaryUploadUrl(formData: FormData, fileType: string) {
    formData.append("upload_preset", this.preset);
    const response = await api.post(
      `https://api.cloudinary.com/v1_1/${this.cloud_name}/${fileType}/upload`,
      formData
    );
    return response;
  },
};
