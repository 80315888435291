import { AxiosInstance } from "axios";

export default class YollloBusinessAdminController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/admin/business";
    this.api = api;
  }

  public async disconnect(userId: string) {
    const response = await this.api.post(`${this.url}/disconnect`, "", {
      params: {
        userId: userId,
      },
    });

    return response;
  }

  public async subscribe(userId: string) {
    const response = await this.api.get(`${this.url}/subscribe`, {
      params: {
        userId: userId,
      },
    });

    return response;
  }

  public async credentials(userId: string) {
    const response = await this.api.get(`${this.url}/credentials`, {
      params: {
        userId: userId,
      },
    });

    return response;
  }

  public async userByCredentials(email: string) {
    const response = await this.api.get(`${this.url}/user_by_credentials`, {
      params: {
        email: email,
      },
    });

    return response;
  }
}
