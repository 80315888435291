import { AxiosInstance } from "axios";
import AdvertisingStatusEnum from "@/enums/advertisingStatusEnum";

export default class contentAdvertisingAdminController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/admin/content_advertising";
    this.api = api;
  }

  public async userSettings() {
    const response = await this.api.get(`${this.url}/user_settings`);

    return response;
  }

  public async contentSettings() {
    const response = await this.api.get(`${this.url}/content_settings`);

    return response;
  }

  public async updateUserSettings(viewPrice: number) {
    const response = await this.api.post(
      `${this.url}/update_user_settings`,
      "",
      {
        params: {
          viewPrice: viewPrice,
        },
      }
    );

    return response;
  }

  public async updateContentSettings(viewPrice: number) {
    const response = await this.api.post(
      `${this.url}/update_content_settings`,
      "",
      {
        params: {
          viewPrice: viewPrice,
        },
      }
    );

    return response;
  }

  public async approveUser(advertisingId: number) {
    const response = await this.api.post(`${this.url}/approve_user`, "", {
      params: {
        advertisingId: advertisingId,
      },
    });

    return response;
  }

  public async approveContent(advertisingId: number) {
    const response = await this.api.post(`${this.url}/approve_content`, "", {
      params: {
        advertisingId: advertisingId,
      },
    });

    return response;
  }

  public async rejectContent(advertisingId: number) {
    const response = await this.api.post(`${this.url}/reject_content`, "", {
      params: {
        advertisingId: advertisingId,
      },
    });

    return response;
  }

  public async rejectUser(advertisingId: number) {
    const response = await this.api.post(`${this.url}/reject_user`, "", {
      params: {
        advertisingId: advertisingId,
      },
    });

    return response;
  }

  public async getUserByStatus(
    status: AdvertisingStatusEnum,
    page: number,
    size: number,
    sort: string
  ) {
    const response = await this.api.get(`${this.url}/user/by_status`, {
      params: {
        status: status,
        page: page,
        size: size,
        sort: sort,
      },
    });

    return response;
  }

  public async getContentByStatus(
    status: AdvertisingStatusEnum,
    page: number,
    size: number,
    sort: string
  ) {
    const response = await this.api.get(`${this.url}/content/by_status`, {
      params: {
        status: status,
        page: page,
        size: size,
        sort: sort,
      },
    });

    return response;
  }
}
