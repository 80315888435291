<template>
  <div class="NoRecordsFound">
    <img src="/pictures/NoRecodsFound.svg" />
    <span>No records found</span>
  </div>
</template>

<style scoped lang="scss">
.NoRecordsFound {
  display: flex;
  height: 50%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
