import { AxiosInstance } from "axios";
import contentFilter from "@/types/contentFilter";
import ContentStatusEnum from "@/enums/contentStatusEnum";
import ContentEditType from "@/types/contentEditType";

export default class contentController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/admin/content";
    this.api = api;
  }

  // sort: sort,
  public async getUsersContent(
    page: number,
    size: number,
    filter: contentFilter,
    sort: string
  ) {
    const response = await this.api?.post(`${this.url}/user_contents`, filter, {
      params: {
        page: page,
        size: size,
        sort: sort,
      },
    });

    return response;
  }

  public async getContent(
    page: number,
    size: number,
    filter: contentFilter,
    sort: string
  ) {
    const response = await this.api?.post(`${this.url}/by_filter`, filter, {
      params: {
        page: page,
        size: size,
        sort: sort,
      },
    });

    return response;
  }

  public async updateData(contentId: string, data: ContentEditType) {
    const response = await this.api?.put(`${this.url}/update_data`, data, {
      params: {
        contentId: contentId,
      },
    });

    return response;
  }

  public async updateVisible(contentId: string, status: ContentStatusEnum) {
    const response = await this.api?.put(`${this.url}/update_visible`, null, {
      params: {
        contentId: contentId,
        status: status,
      },
    });

    return response;
  }

  public async delete(contentId: string) {
    const response = await this.api?.delete(`${this.url}/delete`, {
      params: {
        contentId: contentId,
      },
    });

    return response;
  }
}
