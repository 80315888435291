export enum PaymentEnum {
  CREATE_NFT_TOKEN_721 = "Nft721",
  CREATE_SIMPLE_AUCTION_721 = "SimpleAuction721",
  BUY_TOKEN_AT_SIMPLE_AUCTION_721 = "BuySimpleAuction721",
  PLACE_BID_ON_TIME_AUCTION_721 = "BidTimeAuction721",
  CREATE_TIME_AUCTION_721 = "TimeAuction721",
  CHECK_TIME_AUCTION_721 = "CheckTimeAuction721",
  CREATE_OFB_AUCTION_721 = "OfbAuction721",
  PLACE_BID_ON_OFB_AUCTION_721 = "BidOfbAuction721",
  CANCEL_BID_ON_OFB_AUCTION_721 = "CancelBidOfbAuction721",
  SELECT_WINNER_BID_ON_OFB_AUCTION_721 = "SelectWinnerBidOfb721",
  CLOSE_AUCTION_721 = "Close721",
  TRANSFER_TOKEN_721 = "Transfer721",
  CREATE_TOKEN_AND_SIMPLE_AUCTION_721 = "NftAndSimple721",
  CREATE_TOKEN_AND_TIME_AUCTION_721 = "NftAndSimple721",
  CREATE_TOKEN_AND_OFB_AUCTION_721 = "NftAndOfb721",
  CREATE_NFT_TOKEN_1155 = "Nft1155",
  CREATE_SIMPLE_AUCTION_1155 = "Simple1155",
  BUY_TOKEN_AT_SIMPLE_AUCTION_1155 = "BuySimple1155",
  CREATE_OFB_AUCTION_1155 = "Ofb1155",
  PLACE_BID_ON_OFB_AUCTION_1155 = "PlaceBidOfb1155",
  CANCEL_BID_ON_OFB_AUCTION_1155 = "CancelBidOfb1155",
  SELECT_WINNER_BID_ON_OFB_AUCTION_1155 = "SelectWinnerBidOfb1155",
  CLOSE_AUCTION_1155 = "Close1155",
  TRANSFER_TOKEN_1155 = "Transfer1155",
  CREATE_TOKEN_AND_SIMPLE_AUCTION_1155 = "NftAndSimple1155",
  CREATE_TOKEN_AND_OFB_AUCTION_1155 = "NftAndOfb1155",

  REVERT_BID_OFB_721 = "RevertBidOfb721",
  RECEIVE_SIMPLE_AUCTION_721 = "ReceiveSimpleAuction721",
  RECEIVE_TIME_AUCTION_721 = "ReceiveTimeAuction721",
  RECEIVE_BID_FROM_TIME_AUCTION_721 = "ReceiveBidFromTimeAuction721",
  RECEIVE_CANCEL_BID_AUCTION_721 = "ReceiveCancelBidAuction721",
  RECEIVE_LOSE_RATE_AUCTION_721 = "ReceiveLoseRateAuction721",
  RECEIVE_WINN_BID_OFB_AUCTION_721 = "ReceiveWinnBidOfbAuction721",
  RECEIVE_CLOSE_RATE_AUCTION_1155 = "ReceiveCloseRateAuction1155",
  RECEIVE_PURCHASE_SIMPLE_AUCTION_1155 = "ReceivePurchaseSimpleAuction1155",
  RECEIVE_CANCEL_BID_AUCTION_1155 = "ReceiveCancelBidAuction1155",
  RECEIVE_PURCHASE_OFB_AUCTION_1155 = "ReceivePurchaseOfbAuction1155",
  RECEIVE_LOSE_RATE_OFB_AUCTION_1155 = "ReceiveLoseRateOfbAuction1155",
  ROYALTY_BUY_SIMPLE_AUCTION_721 = "RoyaltyBuySimpleAuction721",
  ROYALTY_BUY_TIME_AUCTION_721 = "RoyaltyBuyTimeAuction721",
  ROYALTY_BUY_OFB_AUCTION_721 = "RoyaltyBuyOfbAuction721",
  ROYALTY_BUY_SIMPLE_AUCTION_1155 = "RoyaltyBuySimpleAuction1155",
  ROYALTY_BUY_OFB_AUCTION_1155 = "RoyaltyBuyOfbAuction1155",
}
