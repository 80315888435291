import ReportStatusEnum from "@/enums/reportStatusEnum";

type StateType = {
  page: number;
  size: number;
  totalPages: number;
  reportId: null | string;
  status: ReportStatusEnum;
  totalItems: null | number;
};
const state: StateType = {
  page: 1,
  size: 20,
  totalPages: 1,
  reportId: null,
  status: ReportStatusEnum.ACTIVE,
  totalItems: null,
};

export default {
  namespaced: true,
  state: state,
  getters: {
    getPage: (state: { page: number }): number => state.page,

    getSize: (state: { size: number }): number => state.size,

    getTotalPages: (state: { totalPages: number }): number => state.totalPages,

    getReportId: (state: { reportId: null | string }): null | string =>
      state.reportId,

    getStatus: (state: { status: ReportStatusEnum }): ReportStatusEnum =>
      state.status,

    getTotalItems: (state: { totalItems: null | number }): null | number =>
      state.totalItems,
  },
  mutations: {
    setPage: (state: { page: number }, val: number) => (state.page = val),

    setTotalPages: (state: { totalPages: number }, val: number) =>
      (state.totalPages = val),

    setSize: (state: { size: number }, val: number) => (state.size = val),

    setReportId: (state: { reportId: null | string }, val: null | string) =>
      (state.reportId = val),

    setStatus: (state: { status: ReportStatusEnum }, val: ReportStatusEnum) =>
      (state.status = val),

    setTotalItems: (state: { totalItems: null | number }, val: null | number) =>
      (state.totalItems = val),
  },
};
