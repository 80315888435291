import { AxiosInstance } from "axios";

import role from "@/enums/userRole";
import UserSettingsType from "@/types/userSettingstype";

export default class AdminUserController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/admin/users";
    this.api = api;
  }

  public async changeEmail(userId: string, email: string) {
    const response = await this.api.put(`${this.url}/change_email`, null, {
      params: {
        userId: userId,
        email: email,
      },
    });

    return response;
  }

  public async getUsers(size: number, page: number, sort: string) {
    const response = await this.api.get(`${this.url}/get_all_users`, {
      params: {
        size: size,
        page: page,
        sort: sort,
      },
    });

    return response;
  }

  public async searchUsers(
    size: number,
    page: number,
    search: string,
    sort: string
  ) {
    const response = await this.api.get(`${this.url}/search_users`, {
      params: {
        size: size,
        page: page,
        name: search,
        sort: sort,
      },
    });

    return response;
  }

  public async getUser(search: string) {
    const response = await this.api.get(`${this.url}/search_user_by_id`, {
      params: {
        userId: search,
      },
    });

    return response;
  }

  public async banUser(userId: string) {
    const response = await this.api.put(`${this.url}/ban_user`, "", {
      params: {
        userId: userId,
      },
    });

    return response;
  }

  public async unbanUser(userId: string) {
    const response = await this.api.put(`${this.url}/un_ban_user`, "", {
      params: {
        addDurationOfBlock: false,
        userId: userId,
      },
    });

    return response;
  }

  public async getAllFollowers(size: number, page: number, nickname: string) {
    const response = await this.api.get(`${this.url}/followers`, {
      params: {
        size: size,
        page: page,
        nickname: nickname,
      },
    });

    return response;
  }

  public async getAllFollowings(size: number, page: number, nickname: string) {
    const response = await this.api.get(`${this.url}/followings`, {
      params: {
        size: size,
        page: page,
        nickname: nickname,
      },
    });

    return response;
  }

  public async searchFollowers(
    size: number,
    page: number,
    nickname: string,
    search: string
  ) {
    const response = await this.api.get(`${this.url}/search_followers`, {
      params: {
        size: size,
        page: page,
        nickname: nickname,
        search: search,
      },
    });

    return response;
  }

  public async searchFollowings(
    size: number,
    page: number,
    nickname: string,
    search: string
  ) {
    const response = await this.api.get(`${this.url}/search_followings`, {
      params: {
        size: size,
        page: page,
        nickname: nickname,
        search: search,
      },
    });

    return response;
  }

  public async editUser(userId: string, settings: UserSettingsType) {
    const response = await this.api.put(`${this.url}/edit_user`, settings, {
      params: {
        userId: userId,
      },
    });

    return response;
  }

  public async changeUsersRole(role: role, userId: string) {
    const response = await this.api.put(`${this.url}/change_role`, null, {
      params: {
        role: role,
        userId: userId,
      },
    });

    return response;
  }

  public async disableTwoFa(userId: string) {
    const response = await this.api.put(`${this.url}/disable_two_fa`, null, {
      params: {
        userId: userId,
      },
    });

    return response;
  }

  public async completeRegistration(userId: string) {
    const response = await this.api.put(
      `${this.url}/complete_registration`,
      null,
      {
        params: {
          userId: userId,
        },
      }
    );

    return response;
  }

  public async deleteUser(userId: string) {
    const response = await this.api.delete(`${this.url}/delete_user`, {
      params: {
        userId: userId,
      },
    });

    return response;
  }
}
