<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<style>
html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
