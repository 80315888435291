import { AxiosInstance } from "axios";

export default class referralProgramController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/referral";
    this.api = api;
  }

  public async UserParentReferral(userId: string) {
    const response = await this.api.get(`${this.url}/user_parent_referral`, {
      params: {
        userId: userId,
      },
    });

    return response;
  }

  public async deleteUserReferrals(userIdChild: string, userIdParent: string) {
    const response = await this.api.delete(
      `${this.url}/delete_user_referrals`,
      {
        params: {
          userIdParent: userIdParent,
          userIdChild: userIdChild,
        },
      }
    );

    return response;
  }

  public async addUserReferrals(userIdChild: string, userIdParent: string) {
    const response = await this.api.post(
      `${this.url}/add_user_referrals`,
      null,
      {
        params: {
          userIdParent: userIdParent,
          userIdChild: userIdChild,
        },
      }
    );

    return response;
  }

  public async getUserReferrals(size: number, page: number, userId: string) {
    const response = await this.api.get(`${this.url}/user_referrals`, {
      params: {
        size: size,
        page: page,
        userId: userId,
      },
    });

    return response;
  }
}
