import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faBars,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

library.add(faBars, faArrowRightFromBracket);

export default FontAwesomeIcon;
