import { AxiosInstance } from "axios";

export default class confirmLogInController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url;
    this.api = api;
  }

  public async settingsForUser(userId: string) {
    const response = await this.api.get(
      `${this.url}/confirm_login/settings_for_user`,
      {
        params: {
          userId: userId,
        },
      }
    );

    return response;
  }
}
