import { AxiosInstance } from "axios";

export default class ChatApi {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url;
    this.api = api;
  }

  public async createChatRoom(userId: string) {
    const response = await this.api.post(`${this.url}/chat/get-chat`, {
      userId: userId,
    });

    return response;
  }

  public async findAllMessage(chatId: string, size: number, page: number) {
    const response = await this.api.get(`${this.url}/chat/all-messages`, {
      params: {
        chatId: chatId,
        pageable: {
          size: size,
          page: page,
        },
      },
    });

    return response;
  }
}
