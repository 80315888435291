import { AxiosInstance } from "axios";

export default class AdminContentCategoryController {
  private url: string;
  private api: AxiosInstance | undefined;

  constructor(url: string, api: AxiosInstance | undefined) {
    this.url = url + "/admin/category";
    this.api = api;
  }

  public async updateContentCategory(
    contentCategoryId: string,
    contentCategoryRequest: {
      fileUrl: string | null;
      name: string | null;
      fileId: string | null;
    }
  ) {
    const response = await this.api?.put(
      `${this.url}/update_content_category`,
      contentCategoryRequest,
      {
        params: {
          contentCategoryId: contentCategoryId,
        },
      }
    );

    return response;
  }

  public async createContentCategory(
    fileUrl: string | null,
    name: string,
    fileId: string | null
  ) {
    const response = await this.api?.post(
      `${this.url}/create_content_category`,
      {
        fileUrl: fileUrl,
        name: name,
        fileId: fileId,
      }
    );

    return response;
  }

  public async deleteCategory(contentCategoryId: string) {
    const response = await this.api?.delete(
      `${this.url}/delete_content_category`,
      {
        params: { contentCategoryId: contentCategoryId },
      }
    );

    return response;
  }
}
