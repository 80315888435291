import Creator from "@/types/creator";

export enum RatingMarksTransactionsTypeStatus {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
}

export enum RatingMarksTransactionsTypSide {
  CREATOR = "CREATOR",
  PRODUCER = "PRODUCER",
}

type ratingMarksTransactionsType = {
  created: number;
  id: string;
  publicationWebId: number;
  ratingMarksCount: number;
  side: RatingMarksTransactionsTypSide;
  status: RatingMarksTransactionsTypeStatus;
  unFrozen: null | number;
  user: Creator;
  yollloTokensCount: number;
};

export default ratingMarksTransactionsType;
