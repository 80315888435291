import { content_category_controller } from "@/requests/controllers";

const getData = async (page: number, size: number) => {
  try {
    const response = await content_category_controller.getAllContentCategories(
      size,
      page
    );

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const searchData = async (page: number, size: number, search: string) => {
  try {
    const response =
      await content_category_controller.searchAllContentCategoriesByName(
        search,
        size,
        page
      );

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const mainFunction = (page: number, size: number, search?: string) =>
  search === "" || search === undefined
    ? getData(page, size)
    : searchData(page, size, search);

export default mainFunction;
