import { AxiosInstance } from "axios";

export default class BalancesAdminController {
  private startUrl: string;
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url;
    this.startUrl = url;
    this.api = api;
  }

  public async getUserBalances(userId: string) {
    const response = await this.api.get(
      `${this.url}/admin/balances/user_wallets`,
      {
        params: {
          userId: userId,
        },
      }
    );

    return response;
  }

  public async getUserActiveWithdrawRequests(
    userId: string,
    size: number,
    page: number,
    sort: string
  ) {
    const response = await this.api.get(
      `${this.url}/balances/user_active_withdraw_requests`,
      {
        params: {
          userId: userId,
          size: size,
          page: page,
          sort: sort,
        },
      }
    );

    return response;
  }

  public async getUserTransactions(
    userId: string,
    size: number,
    page: number,
    sort: string
  ) {
    const response = await this.api.get(
      `${this.url}/balances/user_transactions`,
      {
        params: {
          userId: userId,
          size: size,
          page: page,
          sort: sort,
        },
      }
    );

    return response;
  }

  public async reject(requestId: string) {
    const response = await this.api.post(
      `${this.url}/admin/balances/withdraw/reject`,
      "",
      {
        params: {
          requestId: requestId,
        },
      }
    );

    return response;
  }

  public async approve(requestId: string) {
    const response = await this.api.post(
      `${this.url}/admin/balances/withdraw/approve`,
      "",
      {
        params: {
          requestId: requestId,
        },
      }
    );

    return response;
  }

  public async transactions(size: number, page: number) {
    const response = await this.api.get(
      `${this.url}/admin/balances/transactions`,
      {
        params: {
          size: size,
          page: page,
        },
      }
    );

    return response;
  }

  public async searchTransactions(size: number, page: number, email: string) {
    const response = await this.api.get(
      `${this.url}/admin/balances/user_transactions`,
      {
        params: {
          size: size,
          page: page,
          email: email,
        },
      }
    );

    return response;
  }

  public async getNotActiveFiatWithdrawRequests(size: number, page: number) {
    const response = await this.api.get(
      `${this.url}/admin/balances/not_active_fiat_withdraw_requests`,
      {
        params: {
          size: size,
          page: page,
          sort: "created,desc",
        },
      }
    );

    return response;
  }

  public async getUserNotActiveFiatWithdrawRequests(
    size: number,
    page: number,
    email: string
  ) {
    const response = await this.api.get(
      `${this.url}/admin/balances/user_not_active_fiat_withdraw_requests`,
      {
        params: {
          size: size,
          page: page,
          sort: "created,desc",
          email: email,
        },
      }
    );

    return response;
  }

  public async getNotActiveCryptoWithdrawRequests(size: number, page: number) {
    const response = await this.api.get(
      `${this.url}/admin/balances/not_active_crypto_withdraw_requests`,
      {
        params: {
          size: size,
          page: page,
          sort: "created,desc",
        },
      }
    );

    return response;
  }

  public async getUserNotActiveCryptoWithdrawRequests(
    size: number,
    page: number,
    email: string
  ) {
    const response = await this.api.get(
      `${this.url}/admin/balances/user_not_active_crypto_withdraw_requests`,
      {
        params: {
          size: size,
          page: page,
          sort: "created,desc",
          email: email,
        },
      }
    );

    return response;
  }

  public async getActiveFiatWithdrawRequests(size: number, page: number) {
    const response = await this.api.get(
      `${this.url}/admin/balances/active_fiat_withdraw_requests`,
      {
        params: {
          size: size,
          page: page,
          sort: "created,desc",
        },
      }
    );

    return response;
  }

  public async getUserActiveFiatWithdrawRequests(
    size: number,
    page: number,
    email: string
  ) {
    const response = await this.api.get(
      `${this.url}/admin/balances/user_active_fiat_withdraw_requests`,
      {
        params: {
          size: size,
          page: page,
          sort: "created,desc",
          email: email,
        },
      }
    );

    return response;
  }

  public async getActiveCryptoWithdrawRequests(size: number, page: number) {
    const response = await this.api.get(
      `${this.url}/admin/balances/active_crypto_withdraw_requests`,
      {
        params: {
          size: size,
          page: page,
          sort: "created,desc",
        },
      }
    );

    return response;
  }

  public async getUserActiveCryptoWithdrawRequests(
    size: number,
    page: number,
    email: string
  ) {
    const response = await this.api.get(
      `${this.url}/admin/balances/user_active_crypto_withdraw_requests`,
      {
        params: {
          size: size,
          page: page,
          sort: "created,desc",
          email: email,
        },
      }
    );

    return response;
  }

  public async getRatingMarks(userId: string) {
    const response = await this.api.get(
      `${this.url}/admin/balances/user_rating_marks`,
      {
        params: {
          userId: userId,
        },
      }
    );

    return response;
  }

  public async getSystemBalances() {
    const response = await this.api.get(
      `${this.url}/admin/balances/system_balances`
    );

    return response;
  }

  public async getUserSpendTransactions(
    page: number,
    size: number,
    userId: string,
    sort: string
  ) {
    const response = await this.api.get(
      `${this.startUrl}/balances/user_spend_transactions`,
      {
        params: {
          userId: userId,
          page: page,
          size: size,
          sort: sort,
        },
      }
    );

    return response;
  }
}
