import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

import routes from "./routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    localStorage.getItem("accessToken") !== null
      ? next()
      : (() => {
          store.commit(
            "general/setrouteBeforeLogingOut",
            router.currentRoute.value.fullPath
          );
          next("/log-in");
        })();
  } else if (
    to.name === "LogIn" &&
    localStorage.getItem("accessToken") !== null
  )
    next("/");
  else next();
});

export default router;
