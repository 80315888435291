import { admin_user_controller } from "@/requests/controllers";

const getData = async (page: number, size: number) => {
  try {
    const response = await admin_user_controller.getUsers(
      size,
      page,
      "created,desc"
    );

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const searchData = async (page: number, size: number, search: string) => {
  try {
    const response = await admin_user_controller.searchUsers(
      size,
      page,
      search,
      "created,desc"
    );

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const mainFunction = (page: number, size: number, search?: string) =>
  search === "" || search === undefined
    ? getData(page, size)
    : searchData(page, size, search);

export default mainFunction;
