import { AxiosInstance } from "axios";

export default class contentCategoryController {
  private url: string;
  private api: AxiosInstance | undefined;

  constructor(url: string, api: AxiosInstance | undefined) {
    this.url = url + "/category";
    this.api = api;
  }

  public async searchAllContentCategoriesByName(
    search: string,
    size: number,
    page: number
  ) {
    const response = await this.api?.get(
      `${this.url}/search_all_content_categories_by_name`,
      {
        params: {
          search: search,
          size: size,
          page: page,
        },
      }
    );

    return response;
  }

  public async getAllContentCategories(size: number, page: number) {
    const response = await this.api?.get(
      `${this.url}/get_all_content_categories`,
      {
        params: {
          size: size,
          page: page,
        },
      }
    );

    return response;
  }
}
