const func2 = (val: number): string => {
  const stringVal = String(val);
  return stringVal.length === 1 ? `0${stringVal}` : stringVal;
};

const func = (val: Array<number>): string => {
  if (val.length < 3) return "something went wrong";
  if (val.length < 5) return `${func2(val[2])}/${func2(val[1])}/${val[0]}`;
  return `${func2(val[2])}/${func2(val[1])}/${val[0]} ${func2(val[3])}:${func2(
    val[4]
  )}`;
};

export default func;
