import { AxiosInstance } from "axios";

export default class SupportQuestionController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/support";
    this.api = api;
  }

  public async findAll(size: number, page: number) {
    const response = await this.api.get(`${this.url}/find_all`, {
      params: {
        size: size,
        page: page,
      },
    });

    return response;
  }

  public async findAllToAnswer(size: number, page: number) {
    const response = await this.api.get(`${this.url}/find_all_to_answer`, {
      params: {
        size: size,
        page: page,
      },
    });

    return response;
  }
}
