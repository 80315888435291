import { AxiosInstance } from "axios";

export default class KycAdminController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/kyc/admin";
    this.api = api;
  }

  public async kycGetAll(size: number, page: number) {
    const response = await this.api.get(`${this.url}/all`, {
      params: {
        size: size,
        page: page,
      },
    });

    return response;
  }

  public async kycGetOnlyPending(size: number, page: number) {
    const response = await this.api.get(`${this.url}`, {
      params: {
        size: size,
        page: page,
      },
    });

    return response;
  }

  public async kycGetDocuments(userId: string) {
    const response = await this.api.get(`${this.url}/documents`, {
      params: {
        userId: userId,
      },
    });

    return response;
  }

  public async kycApprove(userId: string) {
    const response = await this.api.post(`${this.url}/approve`, "", {
      params: {
        userId: userId,
      },
    });

    return response;
  }

  public async kycReject(userId: string, message: string) {
    const response = await this.api.post(`${this.url}/reject`, message, {
      params: {
        userId: userId,
      },
    });

    return response;
  }
}
