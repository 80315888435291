import UserType from "@/types/userType";
import SubscriptionType from "@/types/subscriptionType";
import UserRatingMarks from "@/types/userRatingMarks";
import SettingsForUserType from "@/types/settingsForUserType";

type stateType = {
  user: UserType | null;
  suscription: SubscriptionType | null;
  credentials: string | null;
  ratingMarks: UserRatingMarks | null;
  settingsForUser: SettingsForUserType | null;
};
const state: stateType = {
  user: null,
  suscription: null,
  credentials: null,
  ratingMarks: null,
  settingsForUser: null,
};

export default {
  namespaced: true,
  state: state,

  getters: {
    getUser: (state: stateType): UserType | null => state.user,

    getCredentials: (state: stateType): string | null => state.credentials,

    getSuscription: (state: stateType): SubscriptionType | null =>
      state.suscription,

    getRatingMarks: (state: stateType): UserRatingMarks | null =>
      state.ratingMarks,

    getSettingsForUser: (state: stateType): SettingsForUserType | null =>
      state.settingsForUser,
  },

  mutations: {
    setUser: (state: stateType, value: UserType | null) => (state.user = value),

    setCredentials: (state: stateType, value: string | null) =>
      (state.credentials = value),

    setSuscription: (state: stateType, value: SubscriptionType | null) =>
      (state.suscription = value),

    setRatingMarks: (state: stateType, value: UserRatingMarks | null) =>
      (state.ratingMarks = value),

    setSettingsForUser: (state: stateType, value: SettingsForUserType | null) =>
      (state.settingsForUser = value),
  },
};
