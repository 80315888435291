import { AxiosInstance } from "axios";
import ReportStatusEnum from "@/enums/reportStatusEnum";

export default class AdminReportsController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/admin/reports";
    this.api = api;
  }

  //users
  public async getReportedUsers(
    page: number,
    size: number,
    status?: ReportStatusEnum
  ) {
    const response = await this.api.get(`${this.url}/users`, {
      params: {
        page: page,
        size: size,
        status: status,
      },
    });

    return response;
  }

  public async reportedUserAnswer(reportId: string, message: string) {
    const response = await this.api.post(`${this.url}/users/answer`, {
      reportId: reportId,
      message: message,
    });

    return response;
  }

  //reply_comments
  public async getReportedReplyComments(
    page: number,
    size: number,
    status?: ReportStatusEnum
  ) {
    const response = await this.api.get(`${this.url}/reply_comments`, {
      params: {
        page: page,
        size: size,
        status: status,
      },
    });

    return response;
  }

  public async reportedReplyCommentAnswer(reportId: string, message: string) {
    const response = await this.api.post(`${this.url}/reply_comments/answer`, {
      reportId: reportId,
      message: message,
    });

    return response;
  }

  //content
  public async getReportedContent(
    page: number,
    size: number,
    status?: ReportStatusEnum
  ) {
    const response = await this.api.get(`${this.url}/content`, {
      params: {
        page: page,
        size: size,
        status: status,
      },
    });

    return response;
  }

  public async reportedContentAnswer(reportId: string, message: string) {
    const response = await this.api.post(`${this.url}/content/answer`, {
      reportId: reportId,
      message: message,
    });

    return response;
  }

  //comments
  public async getReportedComments(
    page: number,
    size: number,
    status?: ReportStatusEnum
  ) {
    const response = await this.api.get(`${this.url}/comments`, {
      params: {
        page: page,
        size: size,
        status: status,
      },
    });

    return response;
  }

  public async reportedCommentAnswer(reportId: string, message: string) {
    const response = await this.api.post(`${this.url}/comments/answer`, {
      reportId: reportId,
      message: message,
    });

    return response;
  }
}
