import { RouteRecordRaw } from "vue-router";

import DefaultPage from "@/components/general/DefaultPage.vue";

import LogIn from "@/views/LogIn.vue";

import AdminPage from "@/views/AdminPage.vue";

import AllUsers from "@/views/MainPages/AllUsers.vue";

import Categories from "@/views/MainPages/CategoriesPage.vue";

import User from "@/views/MainPages/User.vue";
import UserSettings from "@/components/User/UserSettings.vue";
import UserPublications from "@/components/User/UserPublications.vue";
import UserTokens from "@/components/User/UserTokens.vue";
import UserSubscribers from "@/components/User/UserSubscribers.vue";
import UserSubscriptions from "@/components/User/UserSubscriptions.vue";
import UserBalances from "@/components/User/UserBalances.vue";
import RatingMarksHistory from "@/components/User/RatingMarksHistory.vue";
import PaymentHistory from "@/components/User/PaymentHistory.vue";
import UserReferals from "@/components/User/UserReferals.vue";
import UserWithdrawRequests from "@/components/User/WithdrawRequests.vue";
import UserTransactions from "@/components/User/Transactions.vue";

import AllContent from "@/views/MainPages/AllContent.vue";

import AllTokens from "@/views/MainPages/AllTokens.vue";

import Messages from "@/views/MainPages/Messages.vue";
import Messager from "@/components/Messages/Messager.vue";

import Reports from "@/views/MainPages/Reports.vue";
import ContentTable from "@/components/Reports/ContentTable.vue";
import UserTable from "@/components/Reports/UserTable.vue";
import CommentTable from "@/components/Reports/CommentTable.vue";
import ReplyTable from "@/components/Reports/ReplyTable.vue";

import Kyc from "@/views/MainPages/Kyc.vue";

import PublicPersonVerification from "@/views/MainPages/PublicPersonVerification.vue";

import Transactions from "@/views/MainPages/Transactions.vue";

import Withdraw from "@/views/MainPages/Withdraw.vue";

import SupportQuestions from "@/views/MainPages/SupportQuestions.vue";

import Dashbord from "@/views/MainPages/Dashbord.vue";

import ContentAdvertising from "@/views/MainPages/ContentAdvertising.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/log-in",
    name: "LogIn",
    component: LogIn,
  },
  {
    path: "/",
    name: "AdminPage",
    component: AdminPage,
    children: [
      {
        path: "all-users",
        name: "AllUsers",
        component: AllUsers,
      },
      {
        path: "",
        name: "Dashbord",
        component: Dashbord,
      },
      {
        path: "all-content",
        name: "AllContent",
        component: AllContent,
      },
      {
        path: "all-nft",
        name: "AllTokens",
        component: AllTokens,
      },
      {
        path: "categories",
        name: "Categories",
        component: Categories,
      },
      {
        path: "user/:name",
        name: "User",
        component: User,
        children: [
          {
            path: "publications",
            name: "UserPublications",
            component: UserPublications,
          },
          {
            path: "tokens",
            name: "UserTokens",
            component: UserTokens,
          },
          {
            path: "",
            name: "UserSettings",
            component: UserSettings,
          },
          {
            path: "subscribers",
            name: "UserSubscribers",
            component: UserSubscribers,
          },
          {
            path: "subscriptions",
            name: "UserSubscriptions",
            component: UserSubscriptions,
          },
          {
            path: "balances",
            name: "UserBalances",
            component: UserBalances,
          },
          {
            path: "history",
            name: "RatingMarksHistory",
            component: RatingMarksHistory,
          },
          {
            path: "payment",
            name: "UserPaymentHistory",
            component: PaymentHistory,
          },
          {
            path: "referals",
            name: "UserReferals",
            component: UserReferals,
          },
          {
            path: "withdraw_requests",
            name: "UserWithdrawRequests",
            component: UserWithdrawRequests,
          },
          {
            path: "transactions",
            name: "UserTransactions",
            component: UserTransactions,
          },
        ],
      },
      {
        path: "messages",
        name: "Messages",
        component: Messages,
        children: [
          {
            path: ":id",
            name: "Messager",
            component: Messager,
          },
        ],
      },
      {
        path: "reports",
        name: "Reports",
        component: Reports,
        children: [
          {
            path: "content",
            name: "ReportsContent",
            component: ContentTable,
          },
          {
            path: "users",
            name: "ReportsUsers",
            component: UserTable,
          },
          {
            path: "comments",
            name: "ReportsComments",
            component: CommentTable,
          },
          {
            path: "replies",
            name: "ReportsReplies",
            component: ReplyTable,
          },
        ],
      },
      {
        path: "verifications",
        name: "Verifications",
        component: Kyc,
      },
      {
        path: "public-person-verification",
        name: "PublicPersonVerification",
        component: PublicPersonVerification,
      },
      {
        path: "transactions",
        name: "Transactions",
        component: Transactions,
      },
      {
        path: "withdraw",
        name: "Withdraw",
        component: Withdraw,
      },
      {
        path: "support-questions",
        name: "SupportQuestions",
        component: SupportQuestions,
      },
      {
        path: "advertising",
        name: "ContentAdvertising",
        component: ContentAdvertising,
      },
    ],

    meta: { requiresAuth: true },
  },
];
export default routes;
