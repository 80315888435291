type stateType = {
  message: string | null;
};

const state: stateType = {
  message: null,
};

export default {
  namespaced: true,
  state: state,

  getters: {
    getMessage: (state: stateType): string | null => {
      return state.message;
    },
  },

  mutations: {
    setMessage: (state: stateType, value: string | null) => {
      state.message = value;
    },
  },
};
