import axios from "axios";
import router from "../router";
import store from "@/store";

const unauthorizedError = () => {
  store.commit(
    "general/setrouteBeforeLogingOut",
    router.currentRoute.value.fullPath
  );
  router.push("/");
  localStorage.removeItem("accessToken");
};

const api = axios.create();

api.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    return config;
  },
  (error) => {
    console.log(error);
  }
);

api.interceptors.response.use(
  (config) => {
    config.headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    return config;
  },
  (error) => {
    switch (error.response.status) {
      case 400:
        store.commit("setMessage", error.response.data.message);
        store.commit("notification/setMessage", error.response.data.message);
        console.log(error.response.data.message);
        break;
      // case 500:
      case 403:
        unauthorizedError();
        break;
    }
  }
);

export default api;
