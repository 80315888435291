import supportChatRoomType from "@/types/supportChatRoomType";

type stateType = {
  chatRoom: supportChatRoomType | null;
};
const state: stateType = {
  chatRoom: null,
};

export default {
  namespaced: true,
  state: state,
  getters: {
    getChatRoom: (state: {
      chatRoom: supportChatRoomType;
    }): supportChatRoomType => {
      return state.chatRoom;
    },
  },
  mutations: {
    setChatRoom: (
      state: { chatRoom: supportChatRoomType },
      value: supportChatRoomType
    ) => {
      state.chatRoom = value;
    },
  },
};
