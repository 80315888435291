import { AxiosInstance } from "axios";

export default class ChatSupportApi {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/chat-support";
    this.api = api;
  }

  public async findAllChatRooms(size: number, page: number) {
    const response = await this.api.get(`${this.url}/find-all`, {
      params: {
        page: page,
        size: size,
      },
    });

    return response;
  }

  public async findAllChatRoomsToAnswer(size: number, page: number) {
    const response = await this.api.get(`${this.url}/find-all-to-answer`, {
      params: {
        size: size,
        page: page,
      },
    });

    return response;
  }

  public async findMyRooms(size: number, page: number) {
    const response = await this.api.get(`${this.url}/find-my-chats`, {
      params: {
        size: size,
        page: page,
      },
    });

    return response;
  }

  public async appointRoom(chatId: string) {
    const response = await this.api.post(`${this.url}/appoint`, null, {
      params: {
        chatId: chatId,
      },
    });

    return response;
  }

  public async rejectRoom(chatId: string) {
    const response = await this.api.post(`${this.url}/reject`, null, {
      params: {
        chatId: chatId,
      },
    });

    return response;
  }

  public async getMessages(chatId: string, size: number, page: number) {
    const response = await this.api.get(`${this.url}/all-messages-as-support`, {
      params: {
        pageable: {
          size: size,
          page: page,
        },
        chatId: chatId,
      },
    });

    return response;
  }
}
