<template>
  <div class="loader">
    <v-progress-circular
      :size="100"
      :width="10"
      color="#ED6A32"
      indeterminate
    ></v-progress-circular>
    <p>Loading</p>
  </div>
</template>

<style scoped lang="scss">
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  justify-content: center;
}
</style>
