import { admin_user_controller } from "@/requests/controllers";

const getData = async (nickname: string, page: number, size: number) => {
  try {
    const response = await admin_user_controller.getAllFollowers(
      size,
      page,
      nickname
    );

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const searchData = async (
  nickname: string,
  page: number,
  size: number,
  search: string
) => {
  try {
    const response = await admin_user_controller.searchFollowers(
      size,
      page,
      nickname,
      search
    );

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const mainFunction = (
  nickname: string,
  page: number,
  size: number,
  search?: string
) =>
  search === "" || search === undefined
    ? getData(nickname, page, size)
    : searchData(nickname, page, size, search);

export default mainFunction;
