// changes EXAMPLE_TEXT to Example text
export default (textstamp: string) => {
  let text = textstamp;
  if (text !== "") {
    if (text.includes("_")) {
      text = text.split("_").join(" ");
    }
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  }
};
