import { AxiosInstance } from "axios";
import axios from "axios";

import { BCService } from "@/const";

export default class UtilControler {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url;
    this.api = api;
  }

  public async getAllPrices() {
    const response = await this.api.get(`${this.url}/util/price/all`);

    return response;
  }

  public async dashbordInfo() {
    const response = await this.api.get(
      `${this.url}/admin/statistic/total_amount`
    );

    return response;
  }

  public async balancesInfo() {
    // const response = await this.api.get(
    //   `${this.url}/confirm_login/settings_for_user`,
    //   {
    //     params: {
    //       userId: "2dd23a8e-9c9d-4cce-9a28-5ea82f46a293",
    //     },
    //   }
    // );
    // return response;
  }

  public async kek() {
    const response = await this.api.put(
      `${this.url}/admin/users/change_email`,
      "",
      {
        params: {
          userId: "ca962f45-d6df-4290-ba94-efb890045aa9",
          email: "trankimyenmedia@gmail.com",
        },
      }
    );
    return response;
  }
}
