import userType from "@/types/userType";

import CryptoPricesControler from "@/types/cryptoPricesControler";

type stateType = {
  routeBeforeLogingOut: string | null;
  user: userType | null;
  rail: boolean;
  update: boolean;
  cryptoPrices: Array<CryptoPricesControler> | null;
};
const state: stateType = {
  routeBeforeLogingOut: null,
  user: null,
  rail: true,
  update: false,
  cryptoPrices: null,
};

export default {
  namespaced: true,
  state: state,

  getters: {
    getrouteBeforeLogingOut: (state: stateType): string | null => {
      return state.routeBeforeLogingOut;
    },

    getUser: (state: stateType): userType | null => {
      return state.user;
    },

    getRail: (state: stateType): boolean => {
      return state.rail;
    },

    getUpdate: (state: stateType): boolean => {
      return state.update;
    },

    getCryptoPrices: (
      state: stateType
    ): Array<CryptoPricesControler> | null => {
      return state.cryptoPrices;
    },
  },

  mutations: {
    setrouteBeforeLogingOut: (state: stateType, value: string | null) => {
      state.routeBeforeLogingOut = value;
    },

    setUser: (state: stateType, value: userType | null) => {
      state.user = value;
    },

    setRail: (state: stateType, value: boolean) => {
      state.rail = value;
    },

    setUpdate: (state: stateType, value: boolean) => {
      state.update = value;
    },

    setCryptoPrices: (
      state: stateType,
      value: Array<CryptoPricesControler> | null
    ) => {
      state.cryptoPrices = value;
    },
  },
};
