import { createStore } from "vuex";

import user from "./modules/user";
import general from "./modules/general";
import chat from "./modules/chat";
import reports from "./modules/reports";
import notification from "./modules/notification";

export default createStore({
  state: {
    message: "",
  },
  getters: {
    getMessage(state: { message: string }) {
      return state.message;
    },
  },
  mutations: {
    setMessage(state: { message: string }, data: string) {
      state.message = data;
    },
  },
  modules: { user, general, chat, reports, notification },
});
