import { AxiosInstance } from "axios";

export default class ratingMarksAdminController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/admin/rating_marks";
    this.api = api;
  }

  public async ratingMarks(userId: string) {
    const response = await this.api.get(`${this.url}`, {
      params: {
        userId: userId,
      },
    });

    return response;
  }

  public async transactions(size: number, page: number, userId: string) {
    const response = await this.api.get(`${this.url}/transactions`, {
      params: {
        size: size,
        page: page,
        userId: userId,
        sort: "created,desc",
      },
    });

    return response;
  }
}
