export const chat = "http://198.211.110.141:8002";

export const BCService = process.env.VUE_APP_BSC_SERVICE_URL;

export const authService =
  window.location.host === process.env.VUE_APP_DEV_URL || false
    ? process.env.VUE_APP_DEV_AUTH_SERVICE_URL
    : process.env.VUE_APP_MAINNET_AUTH_SERVICE_URL;

export const yollloWebsite = process.env.VUE_APP_YOLLLO_URL;

type CoinImagesType = {
  [key: string]: string;
};

export const coinImages: CoinImagesType = {
  USD: "/pictures/balancesImages/USD.png",
  EUR: "/pictures/balancesImages/EUR.png",
  YOLLLO: "/pictures/balancesImages/YOOO.png",
  MATIC: "/pictures/balancesImages/MATIC.png",
  USDT: "/pictures/balancesImages/USDT.png",
  BTC: "/pictures/balancesImages/BTC.png",
  ETH: "/pictures/balancesImages/ETH.png",
  BNB: "/pictures/balancesImages/BNB.png",
};
