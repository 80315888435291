import { AxiosInstance } from "axios";

export default class applicationVersionsController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url + "/version";
    this.api = api;
  }

  public async getAndroidVersion() {
    const response = await this.api.get(`${this.url}/service/android`);

    return response;
  }

  public async getIOSVersion() {
    const response = await this.api.get(`${this.url}/service/ios`);

    return response;
  }

  public async setVersion(iosVersion: string, androidVersion: string) {
    const response = await this.api.put(`${this.url}`, "", {
      params: {
        iosVersion: iosVersion,
        androidVersion: androidVersion,
      },
    });

    return response;
  }
}
