import { AxiosInstance } from "axios";

export default class adminAuthController {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url;
    this.api = api;
  }

  public async adminlogIn(email: string, password: string) {
    const response = await this.api.post(`${this.url}/admin/auth/login`, {
      email: email,
      password: password,
    });

    return response;
  }
}
