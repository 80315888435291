// changes number to ..., ...k, ...m
export default (number: string) => {
  const length = number.length;
  if (length <= 3) {
    return number;
  }
  if (length <= 6) {
    return number.slice(0, -3) + "k";
  }
  return number.slice(0, -6) + "m";
};
