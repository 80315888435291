import { AxiosInstance } from "axios";

export default class ChatSocketApi {
  private url: string;
  private api: AxiosInstance;

  constructor(url: string, api: AxiosInstance) {
    this.url = url;
    this.api = api;
  }

  public async sendMessage(
    text: string,
    chatRoomId: string,
    imageUrl: Array<string>,
    forwardId: string | null,
    forwardType: string | null
  ) {
    const response = await this.api.post(
      `${this.url}/chat/send-message-as-admin`,
      {
        text: text,
        chatRoomId: chatRoomId,
        photo: imageUrl,
        forwardId: forwardId,
        forwardType: forwardType,
      }
    );

    return response;
  }
}
