import interceptor from "@/plugins/api";

import { chat, authService } from "@/const";

import ChatApi from "./chat/chatApi";
import ChatSocketApi from "./chat/chatSocketApi";
import ChatSupportApi from "./chat/chatSupportApi";

import AdminAuthController from "./controllers/adminAuthController";
import AdminContentCategoryController from "./controllers/adminContentCategoryController";
import AdminContentController from "./controllers/adminContentController";
import AdminReportsController from "./controllers/adminReportsController";
import AdminUserController from "./controllers/adminUserController";
import BalancesAdminController from "./controllers/balancesAdminController";
import ConfirmLoginController from "./controllers/confirmLoginController";
import ContentAdvertisingAdminController from "./controllers/contentAdvertisingAdminController";
import ContentCategoryController from "./controllers/contentCategoryController";
import KycAdminController from "./controllers/kycAdminController";
import PublicPersonRequestAdminController from "./controllers/publicPersonRequestAdminController";
import RatingMarksAdminController from "./controllers/ratingMarksAdminController";
import ReferralProgramController from "./controllers/referralProgramController";
import SupportQuestionController from "./controllers/supportQuestionController";
import UtilControler from "./controllers/utilControler";
import YollloBusinessAdminController from "./controllers/yollloBusinessAdminController";
import ApplicationVersionsController from "./controllers/applicationVersionsController";

//Chat
export const chat_api = new ChatApi(chat, interceptor);

export const chat_socket_api = new ChatSocketApi(chat, interceptor);

export const chat_support_api = new ChatSupportApi(chat, interceptor);

//Auth Service
export const admin_auth_controller = new AdminAuthController(
  authService,
  interceptor
);

export const admin_content_category_controller =
  new AdminContentCategoryController(authService, interceptor);

export const admin_content_controller = new AdminContentController(
  authService,
  interceptor
);

export const admin_reports_controller = new AdminReportsController(
  authService,
  interceptor
);

export const balances_admin_controller = new BalancesAdminController(
  authService,
  interceptor
);

export const content_advertising_admin_controller =
  new ContentAdvertisingAdminController(authService, interceptor);

export const confirm_login_controller = new ConfirmLoginController(
  authService,
  interceptor
);

export const admin_user_controller = new AdminUserController(
  authService,
  interceptor
);

export const content_category_controller = new ContentCategoryController(
  authService,
  interceptor
);

export const kyc_admin_controller = new KycAdminController(
  authService,
  interceptor
);

export const rating_marks_admin_controller = new RatingMarksAdminController(
  authService,
  interceptor
);

export const referral_program_controller = new ReferralProgramController(
  authService,
  interceptor
);

export const support_question_controller = new SupportQuestionController(
  authService,
  interceptor
);

export const public_person_request_admin_controller =
  new PublicPersonRequestAdminController(authService, interceptor);

export const util_controler = new UtilControler(authService, interceptor);

export const yolllo_business_admin_controller =
  new YollloBusinessAdminController(authService, interceptor);

export const application_versions_controller =
  new ApplicationVersionsController(authService, interceptor);
