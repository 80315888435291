const func1 = (
  hash: string,
  state: string,
  currency: string,
  network: string
) => {
  window.open(
    ((): string => {
      if (currency === "ETH") return `https://polygonscan.com/${state}/${hash}`;
      if (currency === "BNB") return `https://bscscan.com/${state}/${hash}`;
      if (currency === "BTC")
        return `https://www.blockchain.com/btc/${state}/${hash}`;
      if (currency === "MATIC")
        return `https://polygonscan.com/${state}/${hash}`;
      if (currency === "USDT") {
        if (network === "BSC") return `https://bscscan.com/${state}/${hash}`;
        if (network === "ETH")
          return `https://ropsten.etherscan.io/${state}/${hash}`;
      }
      if (currency === "USD")
        return `https://ropsten.etherscan.io/${state}/${hash}`;
      if (currency === "EUR")
        return `https://ropsten.etherscan.io/${state}/${hash}`;
      return "";
    })(),
    "_blank"
  );
};

export default func1;
